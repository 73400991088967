<template>
  <suc-date-time-field
    :title="$t('components.fields.day')"
    v-bind="$attrs"
    v-on="$listeners"
  ></suc-date-time-field>
</template>

<script>
import { SucDateTimeField } from "@/components/form";

export default {
  components: {
    SucDateTimeField,
  },
};
</script>

<style></style>
